
import VueCookies, { globalCookiesConfig } from 'vue3-cookies';

export const vueCookies = VueCookies;

globalCookiesConfig({
  expireTimes: '30d',
  path: '/',
  domain: '',
  secure: true,
  sameSite: 'None'
});
